
import Swiper, { Navigation, Pagination } from 'swiper';
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import MicroModal from 'micromodal';
import { initLogoStrip } from './modules/logoStrip.js';
import { initAmenitiesSlider } from './modules/amenitiesSlider.js';
import AOS from 'aos';

function init() {

  MicroModal.init({
    openClass: 'is-open', // [5]
    disableScroll: true, // [6]
    disableFocus: false, // [7]
    awaitOpenAnimation: true, // [8]
    awaitCloseAnimation: true // [9]
  });

  window.Alpine = Alpine;
  Alpine.plugin(collapse)
  Alpine.start();

  AOS.init({disable: 'mobile'});


  const logoStrip = initLogoStrip('.logo-strip');
  const amenitiesSlider = initAmenitiesSlider('.amenities-slider');


}


window.addEventListener("DOMContentLoaded", function () {
  init(); //
  // window.Fancybox = Fancybox;

});
