import Swiper, { Autoplay, FreeMode } from 'swiper';

// swiperConfig.js
export const logoStripConfig = {
  modules: [Autoplay, FreeMode],  // Add required modules
  
  // Enable continuous loop mode
  loop: true,

  // Auto-scroll settings
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
    pauseOnMouseEnter: false,
  },

  // Enable free mode for smooth scrolling
  freeMode: {
    enabled: true,
    momentum: false,
  },

  // Speed of transition between slides
  speed: 3000,

  // Spacing between slides
  spaceBetween: 30,

  // Responsive breakpoints
  breakpoints: {
    320: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    480: {
      slidesPerView: 3,
      spaceBetween: 25
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 30
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 30
    }
  },

  // Optional grabCursor
  grabCursor: false,

  // Prevent user drag/touch interactions (optional)
  allowTouchMove: false,
};

// Initialize function
export function initLogoStrip(elementSelector) {
  return new Swiper(elementSelector, logoStripConfig);
}