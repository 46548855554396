import Swiper, { Autoplay, FreeMode } from 'swiper';

// swiperConfig.js
export const config = {
  modules: [Autoplay, FreeMode],  // Add required modules
// Auto-scroll settings
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
    pauseOnMouseEnter: false,
  },

  // Enable free mode for smooth scrolling
  freeMode: {
    enabled: true,
    momentum: false,
  },

  // Speed of transition between slides
  speed: 3000,
  // Enable continuous loop mode
  loop: true,


  // Spacing between slides
  spaceBetween: 16,

  slidesPerView: 'auto',


};

// Initialize function
export function initAmenitiesSlider(elementSelector) {
  return new Swiper(elementSelector, config);
}